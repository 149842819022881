<template>
    <div id="registerPage">
      <div class="header">
        <div class="title">
          <van-icon @click="$router.back();" v-if="step === 2" class="icon" name="arrow-left" />
          <span>会員登録</span>
        </div>
        <router-link class="nav" :to="{name: 'Login', params: {type: 1}}">
          <span class="name">既に登録済み方は、ログインをお願いします</span>
          <van-icon class="icon" name="arrow" />
        </router-link>
      </div>
      <!--输入密码-->
      <div v-if="step === 2" class="container">

        <div class="input-list">
          <div class="input-item">
            <input class="input" v-model="subData.password" :type="passwordActive1?'text':'password'" placeholder="パスワードを入力">
            <i class="icon icon-eyes" :class="{active: passwordActive1}" @click="passwordActive1 = !passwordActive1"></i>
          </div>
          <div class="input-item">
            <input class="input" v-model="subData.newPassword" :type="passwordActive2?'text':'password'" placeholder="パスワードを再入力">
            <i class="icon icon-eyes" :class="{active: passwordActive2}" @click="passwordActive2 = !passwordActive2"></i>
          </div>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>登録</span>
        </div>
      </div>
      <!--输入邮箱-->
      <div v-else class="container">
        <div class="input-list">
          <div class="input-item">
            <i class="icon icon-email"></i>
            <input class="input" v-model="subData.email" type="email" placeholder="メールアドレスを入力してください">
          </div>
        </div>
        <div class="nav">
          <van-checkbox v-model="agreementChecked" ></van-checkbox>
          <span :style="{color: agreementChecked ? '#fff': ''}">
            <router-link
              :to="{name: 'Agreement', params: { type: 2 }}"
            ><span class="bule">「利用規約」</span></router-link><span class="white">と</span><router-link
               :to="{name: 'Agreement', params: { type: 1 }}"
             ><span class="bule">「プライバシーポリシー」</span></router-link><span class="white">に同意する</span>
          </span>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>次へ</span>
        </div>
      </div>
    </div>
</template>

<script>
import md5 from 'md5';
import { Checkbox } from 'vant';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Register',
  components: {
    'van-checkbox': Checkbox
  },
  data () {
    return {
      subData: {
        email: '',
        password: '',
        newPassword: '',
        gid: '',
        gidType: ''
      },
      passwordActive1: false,
      passwordActive2: false,
      agreementChecked: false,
      step: 1
    };
  },
  beforeRouteLeave (to, form, next) {
    const { $utils } = this;
    $utils.storage.set('inputEmail', this.subData.email);
    const { name } = form;
    if (to.name !== 'Agreement') {
      this.removeKeepAliveInclude(name);
    }
    next();
  },
  computed: {
    ...mapGetters(['ticker'])
  },

  mounted () {
    this.init();
    // const { $utils } = this;
    // $utils.storage.get('inputEmail') ? this.subData.email = $utils.storage.get('inputEmail') : this.subData.email = '';

  },
  watch: {
    $route () {
      this.init();
    },
    'subData.password' (newValue) {
      const reg = /[\u4E00-\u9FA5]|\s*/g;
      this.subData.password = newValue.replace(reg, '');
    },
    'subData.newPassword' (newValue) {
      const reg = /[\u4E00-\u9FA5]|\s*/g;
      this.subData.newPassword = newValue.replace(reg, '');
    }
  },
  methods: {
    ...mapActions(['removeKeepAliveInclude']),
    init () {
      const { $route: { params, query: { email, gid, gidType } }, subData, $router } = this;
      let type = params.type * 1;
      subData.email = email;
      subData.gid = gid;
      subData.gidType = gidType;
      if (!email && type === 2) {
        return $router.replace({
          params: {
            type: 1
          }
        });
      }
      this.step = type;
    },
    async onSubmit () {
      const { subData: { email, gid, password, newPassword, gidType }, agreementChecked, step, $utils, $router, $dialog } = this;
      // 验证邮箱
      if (step === 1) {
        if ($utils.verifyEmail(email) && $utils.verifyEmpty(agreementChecked, '「利用規約」と「プライバシーポリシー」に同意は必要です')) {
          return $dialog.alert({
            title: 'アカウントをアクティブ',
            message: `${email}に認証用リンクを送信しました。ご確認お願い致します。`,
            theme: 'round-button',
            confirmButtonText: 'はい',
            closeOnClickOverlay: true
          }).then(() => {
            this.$http.get('security/registerExistEmail', {
              params: {
                email: email,
                url: 'user/register/2',
                gid: this.ticker.gid,
                type: this.ticker.type
              }
            }).then((res) => {
              $utils.successToast('送信済み');
              // $router.push({
              //   params: {
              //     type: 2
              //   }
              // });
            });
          }).catch((err) => {
            console.log(err);
          });
        }
      }
      // 验证密码
      /* 验证密码是否为空 */
      if (step === 2) {
        let loginEmail = email;
        gidType === 2 ? loginEmail = email : loginEmail = md5('email-code:' + email);
        console.log(gidType, loginEmail, email, typeof email);
        if ($utils.verifyEmpty(password, 'パスワードを入力してください')) {

          /* 验证密码的长度是否大于6小于12 */
          if ($utils.verifyLengeRange(password, 'パスワードの長さは6〜12桁です', 6, 12)) {
            if (password !== newPassword) {
              return $utils.toast('二回のパスワードが一致しません');
            }
            const data = await this.$http.post('security/register', {
              affirmPassword: md5(newPassword),
              email: loginEmail,
              password: md5(password),
              gid: this.ticker.gid || gid,
              type: gidType
            });
            if (data.code === 200) {
              $router.replace({
                name: 'Success',
                params: {
                  type: 3
                }
              });
            }
          }
        }
      }
    }

  }
};
</script>

<style scoped lang="less">
  #registerPage {
    padding: 128px 28px;

    .header {
      margin-bottom: 86px;

      .title,
      .nav {
        display: flex;
        align-items: center;
      }

      .title {
        margin-bottom: 22px;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        color: #fff;

        .icon {
          margin-right: 10px;
        }
      }

      .nav {
        font-size: 28px;
        line-height: 38px;
        color: @primaryBlueColor;
        .icon {
          margin-left: 10px;
        }
      }
    }

    .container {
      .tip {
        margin-bottom: 30px;
        color: #aaa;
        text-align: center;
      }

      .input-list {
        .input-item {
          .icon-email {
            background-image: url("~@/assets/images/icon_news_06.png");
          }

          .icon-eyes {
            background-image: url("~@/assets/images/icon_eyesClose_01.png");

            &.active {
              background-image: url("~@/assets/images/icon_eyesOpen_01.png");
            }
          }
        }
      }

      .nav {
        display: flex;
        align-items: center;
        margin-top: 42px;
        margin-bottom: -72px;
        font-size: 22px;
        line-height: 30px;
        color: #666;
        .bule{
          color: #36abf5;
          text-decoration:underline
        }
        .white{
          color: #fff;
          // text-decoration:underline
        }

      }

      .submit-btn {
        margin-top: 210px;
      }
    }
  }
</style>